<template>
    <section class="success-stories-list">
        <div class="content">
            <PrismicRichText :field="slice.primary.title" class="title" />
            <PrismicRichText :field="slice.primary.headline" class="headline" />
        </div>
        <div class="success-stories">
            <div class="content">
                <div class="success-stories-grid">
                    <ArticleCard v-for="successStory in successStories" :key="successStory.uid" :article="successStory" />
                </div>
                <div class="button-row">
                    <NuxtLink v-slot="{href, navigate}" to="/resources/success-stories" custom>
                        <VJoyButton variant="main" :href="href" @click="navigate">{{ t('resources.successStories.seeAll') }}</VJoyButton>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {PrismicRichText} from '@prismicio/vue';
    import type {SuccessStoriesListSliceDefault, SuccessStoryDocument, SuccessStoryDocumentData} from '@prismicio-types';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import {isFilled} from '@prismicio/helpers';
    import {useAsyncData, useTranslation} from '#imports';
    import usePrismicSearch from '~/composables/content/usePrismicSearch';
    import ArticleCard from '~/components/ArticleCard.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    const props = defineProps<{slice: SuccessStoriesListSliceDefault}>();
    const successStories = [props.slice.primary.successStory1, props.slice.primary.successStory2, props.slice.primary.successStory3].filter(
        (successStory) => isFilled.contentRelationship<'successStory', string, SuccessStoryDocumentData>(successStory),
    ) as (FilledContentRelationshipField<'successStory', string, SuccessStoryDocumentData> | SuccessStoryDocument)[];

    const {t} = useTranslation();

    function fetchSuccessStories() {
        if (successStories.length < 3) {
            // prismic does not allow 'or' queries, so we have to do multiple queries
            const searchQueries = props.slice.items
                .filter((searchFilter) => searchFilter.categoryFilter || searchFilter.categoryFilter)
                .map((item) =>
                    usePrismicSearch({
                        type: 'successStory',
                        pageSize: 3 - successStories.length,
                        category: item.categoryFilter ?? undefined,
                        tags: item.tagFilters?.split(', '),
                        excludes: successStories.map((successStory) => successStory.id),
                    }),
                );
            if (searchQueries.length === 0) {
                searchQueries.push(
                    usePrismicSearch({
                        type: 'successStory',
                        pageSize: 3 - successStories.length,
                        excludes: successStories.map((successStory) => successStory.id),
                    }),
                );
            }
            return Promise.all(searchQueries);
        }
        return Promise.resolve([]);
    }

    const {data: complementarySuccessStories} = await useAsyncData('complementary-success-stories', fetchSuccessStories);

    if (complementarySuccessStories.value) {
        const mostRecentComplementarySuccessStories = complementarySuccessStories.value
            .map((query) => query?.results)
            .flat()
            .filter((successStory) => successStory !== undefined)
            .sort((a, b) => new Date(b?.data?.releaseDate).getTime() - new Date(a?.data?.releaseDate).getTime())
            .slice(0, 3 - successStories.length) as SuccessStoryDocument[];
        successStories.push(...mostRecentComplementarySuccessStories);
    }
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .success-stories-list {
        position: relative;
        padding: 150px 0px;
        @media (max-width: $mobile-breakpoint) {
            padding: 80px 0px;
        }

        .success-stories {
            position: relative;

            .success-stories-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: var(--joy-core-spacing-9);
                justify-items: stretch;
                position: static;
                z-index: 1;
                position: relative;
                overflow: show;

                @media (max-width: $mobile-breakpoint) {
                    grid-template-columns: 1fr;
                }
            }
        }
        .content {
            @include content-width;
            .title p {
                text-align: center;
                color: var(--joy-color-primary-50);
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-secondary-200);
                margin-bottom: var(--joy-core-spacing-5);
            }
            .headline {
                text-align: center;
                margin-bottom: var(--joy-core-spacing-11);
            }
            .button-row {
                display: flex;
                justify-content: center;
                margin-top: 80px;
                @media (max-width: $mobile-breakpoint) {
                    margin-top: var(joy-core-spacing-12);
                }
            }
        }
    }
</style>
